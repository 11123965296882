import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { downloadFile } from '../utils/common';
import { MarketService } from './market.service';

@Injectable()
export class InvoiceService {
	constructor(
		private httpClient: HttpClient,
		private marketService: MarketService,
	) {}

	public getInvoices(
		fromDate: string,
		toDate: string,
		searchString: string | null = null,
		searchCriteria: string,
		jobsiteNames: string[],
		invoiceTypeId: string | null = null,
	) {
		const body = {
			JobsiteNames: jobsiteNames,
			SearchCriteria: searchCriteria,
			SearchString: searchString,
			FromDate: fromDate,
			ToDate: toDate,
			InvoiceType: invoiceTypeId || 'All',
		};
		return this.httpClient.post(`/webapi/auth/${this.marketService.currentMarket}/invoices`, body);
	}

	public fetchInvoiceAndDownload(invoiceId: string, documentDate: string) {
		this.httpClient
			.get(`/webapi/auth/${this.marketService.currentMarket}/invoice/${invoiceId}/${documentDate}/document`, {
				responseType: 'blob',
			})
			.pipe(take(1))
			.subscribe((response) => downloadFile(response, `${invoiceId}.pdf`));
	}
}
